import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body pt-0 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabNavigate = _resolveComponent("TabNavigate")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.user.admin)
          ? (_openBlock(), _createBlock(_component_TabNavigate, {
              key: 0,
              items: [
        {id: 'recent-invoice', router: `/list-invoice/recent`, name: 'Recent Invoices'},
        {id: 'recent-invoice', router: `/list-invoice/watching`, name: 'Watching Invoices'},
        {id: 'my-invoice', router: `/list-invoice/my-invoices`, name: 'My Invoices'},
        {id: 'deleted-invoice', router: `/list-invoice/deleted`, name: 'Deleted Invoices'},
        ]
            }))
          : (_openBlock(), _createBlock(_component_TabNavigate, {
              key: 1,
              items: [
        {id: 'recent-invoice', router: `/list-invoice/recent`, name: 'Recent Invoices'},
        {id: 'recent-invoice', router: `/list-invoice/watching`, name: 'Watching Invoices'},
        {id: 'my-invoice', router: `/list-invoice/my-invoices`, name: 'My Invoices'},
        ]
            }))
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}