
import {computed, defineComponent} from "vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import store from "@/store";

export default defineComponent({
  name: "List",
  components: {TabNavigate},
  setup() {
    const user = computed(() => store.getters.currentUser);
    return {
      user,
    }
  }
})
